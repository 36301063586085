.main-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.ad-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease-in-out;

  .ad-link {
    text-decoration: none;
    color: inherit;
    display: block;

    &:hover {
      color: inherit;
    }
  }
  .ad-logo {
    max-width: 100px; /* You can adjust this value based on your design preferences */
    margin-top: 15px;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .ad-media {
    img {
      width: 100%;
      max-height: 400px;
      height: auto;
      object-fit: contain;
      border-radius: 0.25rem;
    }
  }

  .ad-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .ad-title {
      font-size: 1.2rem;
      font-weight: bold;
      color: black;
    }

    .ad-description {
      font-size: 0.9rem;
      color: black;
    }

    .ad-price {
      font-size: 1.1rem;
      color: black;
    }

    .shop-now-btn {
      align-self: flex-end;
      background-color: blue;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #003366;
      }
    }
  }
}
