/* Set a maximum height for the logo */
.navbar-brand {
  margin-left: 0; // Reset the margin

  img {
    max-height: 40px;
    width: auto;
    padding-left: 10px;
    cursor: pointer;
  }
}

/* Align the navbar contents to the center */
.navbar-collapse {
  display: flex;
  align-items: center;
}

/* Style the search form for small screens */
@media (max-width: 991.98px) {
  .navbar .d-flex {
    width: 100%;
    margin-top: 10px;
  }
}

/* Style the search form for large screens */
@media (min-width: 992px) {
  .navbar .d-flex {
    width: auto;
    margin-top: 0;
  }
}

/* Style the search form for extra small screens */
@media screen and (max-width: 767px) {
  .search-form {
    padding: 0 10px;
  }
}

/* Style the vertical menu button */
.vertical-menu .bi-three-dots-vertical {
  font-size: 2.5rem;
}

.vertical-menu .dropdown-toggle::after {
  display: none;
}

/* Define the height of the navbar and hide it on small screens */
$navbar-height: 65px;
.Nav {
  height: $navbar-height;
  background-color: white;
  position: sticky !important;
  top: 0;
  width: 100%;
  z-index: 9999;
  @media (max-width: 1008px) {
    display: none !important;
  }

  .form-control {
    width: 100%; // Change the width to 100%
    max-width: 500px; // Add a max-width
    border-radius: 20px;
    margin-left: 200px;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0rem;
    border: 0.5px solid #aaa;
  }

  border-bottom: 1px solid #ddd;
}

// New containers styling

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.search-container {
  flex: 2;
  display: flex;
  justify-content: center;
}

.vertical-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  i{
    size: 1rem;
  }
}

/* Define the style of the second search bar and hide it on large screens */
.searchBar {
  padding: 15px;
  position: sticky !important;
  top: 0;
  z-index: 999;
  background-color: white;

  @media (min-width: 1008px) {
    display: none !important;
  }

  .search-icon {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    color: #6c757d;
    cursor: pointer;
  }

  .search-icon:hover {
    color: #495057;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0rem;
    border: 0.5px solid #aaa;
  }
}

/* Define the style of the search bar and hide it on smaller screens */
.Form {
  display: flex;
  align-items: center;
}

.searchBar2 {
  margin-top: 5px;
}

.search-input {
  flex-grow: 1;
  border-radius: 20px;
}

.search-button {
  border-radius: 20px;
}

  // In Navbar.scss

.menu-button {
  display: none;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-right: 7px;
  padding-top: 0 !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  @media (max-width: 1008px) {
    display: block;
  }
}
