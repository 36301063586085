// Fonts

.sidebar-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  
.sidebar-subtitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}


.sidebar {
    width: 100%;
    max-width: 280px;
    height: 100vh !important;
    padding: 1.2rem;
    box-sizing: border-box;
    position: fixed;
    top: 0; // Height of the navbar
    left: 0;
    height: calc(100% - 60px); // Subtract the height of the navbar
    transition: transform 0.3s ease-in-out;
    border: none;
    outline: none;
    box-shadow: none;
    padding-left: 20px;
    z-index: 1000;
  
    .logo {
      display: none;
      width: 60%;
      height: auto;
      padding: 1rem 0;
      text-align: center;
    }
  
    h3 {
      margin-bottom: 0.5rem;
    }
  
    .visit-count {
      margin-bottom: 2rem;
      text-align: center;
  
      .visit-number {
        font-size: 2rem;
        font-weight: bold;
        color: #333;
      }
    }
  
    .featured-ads {
      margin-bottom: 2rem;
  
      .featured-ad {
        padding: 0.5rem 0;
        border-bottom: 1px solid #f0f0f0;
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .category {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: #212529;
    }

    .category:hover {
    background-color: #f8f9fa;
    }

    .category.active {
    color: #0d6efd;
    }

    .category i {
    margin-right: 10px;
    font-size: 1.2rem;
    color: inherit;
    }

    .category svg {
    margin-right: 10px;
    width: 1.2rem;
    height: 1.2rem;
    }

    .categories {
      margin-bottom: 2rem;
  
      .category {
        padding: 0.5rem 0;
        border-bottom: 1px solid #f0f0f0;
  
        &:last-child {
          border-bottom: none;
        }
      }
  
      .show-more {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        color: #007bff;
        cursor: pointer;
        font-size: 0.9rem;
        margin-top: 0.5rem;
  
        svg {
          margin-left: 0.5rem;
          vertical-align: middle;
        }
      }
    }
  
    .footer {
      text-align: center;
  
      .footer-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
        cursor: pointer;
        a {
          margin: 0 0.5rem;
          color: #333;
          text-decoration: none;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
  
      .copyright {
        font-size: 0.8rem;
        color: #777;
      }
    }
  
    @media (max-width: 1008px) {
      width: 80%;
      max-width: none;
      transform: translateX(-100%);
  
      .logo {
        display: block;
      }
    }

 @media (max-width: 660px) {
  .footer-links{
    flex-direction: column;
    a {
      margin-bottom: 0.5rem !important;
    }
  }
 }

    .sidebar-exit {
        display: none;
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        border: none;
        cursor: pointer;
    
        svg {
          color: #333;
          font-size: 1.5rem;
        }
      }
    
      // ...
    
      @media (max-width: 1008px) {
        width: 65%; // Updated width for screens below 1008px
    
        .logo {
          display: block;
        }
    
        .sidebar-exit {
          display: block;
        }
      }
  }

  @media (min-width: 1009px) {
    .sidebar {
      top: 65px !important;
    }
  }

  .sidebar.show {
      transform: translateX(0); 
  }

  /* Custom scrollbar */
::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(185, 185, 185, 0.2);
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(196, 195, 195, 0.4);
  }
  
  /* Firefox custom scrollbar */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }
  
  .featured-ads {
    margin-bottom: 1rem;
  
    a {
      text-decoration: none;
      
      .featured-ad {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        background-color: #f8f9fa;
        padding: 0.5rem 1rem;
        margin-bottom: 0.5rem;
        border-radius: 0.25rem;
        transition: background-color 0.3s ease;
        color: #333;
        
        &:hover {
          background-color: #e9ecef;
          cursor: pointer;
        }
      }
    }
  }
  .position-sticky {
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    padding-bottom: 20px;
  }