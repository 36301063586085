.about {
    padding: 2rem 0;
  
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 1rem;
    }
  
    h2,
    h3 {
      font-family: 'Roboto', sans-serif;
      color: #333;
      margin-bottom: 1rem;
    }
  
    p {
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      color: #666;
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }
  
    ul {
      list-style-type: disc;
      padding-left: 1.5rem;
  
      li {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        color: #666;
        line-height: 1.6;
        margin-bottom: 0.5rem;
      }
    }
  }
  
  @media screen and (min-width: 768px) {
    .about {
      .container {
        padding: 0 2rem;
      }
  
      h2,
      h3 {
        font-size: 1.5rem;
      }
  
      p {
        font-size: 1.1rem;
      }
  
      ul {
        li {
          font-size: 1.1rem;
        }
      }
    }
  }
  